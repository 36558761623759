/* stylelint-disable */
@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=d9c5e265-bd56-4269-804f-3465cd444233");
@font-face{
  font-family:"Kohinoor W05_n3";
  src:url("../fonts/20d60e92-5600-40cf-a097-c24e57d77b86.eot?#iefix") format("eot")
}
@font-face{
  font-family:"Kohinoor W05";
  src:url("../fonts/20d60e92-5600-40cf-a097-c24e57d77b86.eot?#iefix");
  src:url("../fonts/20d60e92-5600-40cf-a097-c24e57d77b86.eot?#iefix") format("eot"),url("../fonts/8a42a8c9-dbec-4d91-b542-57df211a94f2.woff2") format("woff2"),url("../fonts/db7330a4-633b-41e0-b9e3-58d0947ef651.woff") format("woff"),url("../fonts/3ea73295-fff7-46c2-86a3-026e8e01bc52.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face{
  font-family:"Kohinoor W05_n4";
  src:url("../fonts/ecb2a532-eaa2-45fc-a829-a65f62fd0ef9.eot?#iefix") format("eot")
}
@font-face{
  font-family:"Kohinoor W05";
  src:url("../fonts/ecb2a532-eaa2-45fc-a829-a65f62fd0ef9.eot?#iefix");
  src:url("../fonts/ecb2a532-eaa2-45fc-a829-a65f62fd0ef9.eot?#iefix") format("eot"),url("../fonts/76556a43-2eba-454b-abce-324cdcaea0be.woff2") format("woff2"),url("../fonts/e3aa11bc-3032-4d36-973e-85fc25ddec5d.woff") format("woff"),url("../fonts/7d74bdfd-111e-4c96-87f1-6ede2674658d.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face{
  font-family:"Kohinoor W05_n5";
  src:url("../fonts/ddd39d04-6f75-41da-9c1c-0e73d75bc527.eot?#iefix") format("eot")
}
@font-face{
  font-family:"Kohinoor W05";
  src:url("../fonts/ddd39d04-6f75-41da-9c1c-0e73d75bc527.eot?#iefix");
  src:url("../fonts/ddd39d04-6f75-41da-9c1c-0e73d75bc527.eot?#iefix") format("eot"),url("../fonts/907fc057-5316-4abd-96c4-aa6ffe5db18a.woff2") format("woff2"),url("../fonts/366a21de-3b2e-4132-a778-9d92d950cedf.woff") format("woff"),url("../fonts/2f9b1ab4-09b1-4379-a8a7-57baf77ac564.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face{
  font-family:"Kohinoor W05_n4";
  src:url("../fonts/ab7fb55c-c125-46ff-88e5-465cbeae2b8b.eot?#iefix") format("eot")
}
@font-face{
  font-family:"Kohinoor W05";
  src:url("../fonts/ab7fb55c-c125-46ff-88e5-465cbeae2b8b.eot?#iefix");
  src:url("../fonts/ab7fb55c-c125-46ff-88e5-465cbeae2b8b.eot?#iefix") format("eot"),url("../fonts/cc4a9e4e-671b-45b1-b914-653353e6fff5.woff2") format("woff2"),url("../fonts/f791b8ed-ad3a-46c5-8d9b-c02449cd97ec.woff") format("woff"),url("../fonts/2af3ffb8-6664-4df5-b4ca-daf77f997f3d.ttf") format("truetype");
  font-weight: 450;
  font-style: normal;
}
@font-face{
  font-family:"Times W01_i4";
  src:url("../fonts/bb094262-dc4f-48aa-927f-9278a783928c.eot?#iefix") format("eot")
}
@font-face{
  font-family:"Times W01";
  src:url("../fonts/bb094262-dc4f-48aa-927f-9278a783928c.eot?#iefix");
  src:url("../fonts/bb094262-dc4f-48aa-927f-9278a783928c.eot?#iefix") format("eot"),url("../fonts/254aebd4-cb82-49f7-8127-3141510ad10a.woff2") format("woff2"),url("../fonts/8cfe6dc9-5f79-442d-bee6-b46190707bc8.woff") format("woff"),url("../fonts/9e50b8a8-1e14-405c-b605-bcc4f6f29efd.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}
/* stylelint-enable */
